@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
#top .p-mainVisual {
  width: 100%;
  height: 714px;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    #top .p-mainVisual {
      height: 507px; } }
  #top .p-mainVisual__bg {
    width: calc(1063 / 1280 * 100%);
    height: 100%;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual__bg {
        width: calc(100% - 30px); } }
  #top .p-mainVisual__swipe {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: calc(436 / 1063 * 100%);
    background-color: #FFFFFF;
    line-height: 0; }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual__swipe {
        top: 23px;
        transform: none;
        width: 296px; } }
    #top .p-mainVisual__swipe-inner {
      width: 100%;
      height: 100%; }
    #top .p-mainVisual__swipe-wrap {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: -125px; }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__swipe-wrap {
          right: -30px; } }
    #top .p-mainVisual__swipe-item {
      width: 100%;
      height: 23.6vw;
      max-height: 500px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__swipe-item {
          height: 205px; } }
  #top .p-mainVisual__catch {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    max-width: 466px;
    width: 100%;
    color: #FFFFFF; }
    @media screen and (max-width: 1168px) {
      #top .p-mainVisual__catch {
        left: 30px;
        transform: translateY(-50%); } }
    @media screen and (max-width: 1024px) {
      #top .p-mainVisual__catch {
        max-width: 400px; } }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual__catch {
        top: auto;
        bottom: 54px;
        transform: none; } }
    @media screen and (max-width: 568px) {
      #top .p-mainVisual__catch {
        max-width: calc(100% - 30px);
        left: 0;
        padding: 0 15px 0 30px; } }
    #top .p-mainVisual__catch-heading {
      font-size: 48px;
      margin-bottom: 18px; }
      @media screen and (max-width: 1024px) {
        #top .p-mainVisual__catch-heading {
          font-size: 36px; } }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__catch-heading {
          font-size: 28px; } }
      #top .p-mainVisual__catch-heading small {
        font-size: 32px; }
        @media screen and (max-width: 1024px) {
          #top .p-mainVisual__catch-heading small {
            font-size: 26px; } }
        @media screen and (max-width: 768px) {
          #top .p-mainVisual__catch-heading small {
            font-size: 22px; } }
    #top .p-mainVisual__catch-desc {
      font-size: 18px;
      line-height: 1.5; }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__catch-desc {
          font-size: 16px; } }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__catch-desc {
          font-size: 14px; } }
  #top .p-mainVisual__scroll {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 105px;
    right: -55px;
    transform: rotate(90deg); }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual__scroll {
        bottom: 125px;
        right: -68px; } }
    #top .p-mainVisual__scroll-txt {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.05em;
      display: inline-block;
      margin-right: 12px; }
    #top .p-mainVisual__scroll-bar {
      display: inline-block;
      width: 100px;
      height: 2px;
      background-color: #CCCCCC;
      position: relative;
      overflow: hidden; }
      #top .p-mainVisual__scroll-bar:before {
        content: '';
        width: 50%;
        height: 2px;
        position: absolute;
        top: 0;
        left: -50%;
        display: inline-block;
        background-color: #002D99; }

#top #wrapper {
  padding: 0 0; }
  @media screen and (max-width: 1168px) {
    #top #wrapper {
      padding: 60px 0 0; } }

#top #newslist {
  padding-bottom: 0; }
  #top #newslist .c-newslist {
    display: block !important; }
    @media screen and (max-width: 768px) {
      #top #newslist .c-newslist__wrap {
        background-color: #F7F7F7;
        padding: 15px 0 30px; } }
    @media screen and (max-width: 768px) {
      #top #newslist .c-newslist dl dt {
        margin-bottom: 5px; } }
    #top #newslist .c-newslist dl dd {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

#top .p-about .l-col2__detail {
  position: relative; }

#top .p-reason .l-col2__detail {
  position: relative; }
  #top .p-reason .l-col2__detail:before {
    content: '';
    display: block;
    background: url("/inc/image/top/img_loader.jpg") no-repeat center/contain;
    width: 968px;
    height: 519px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: -1; }
    @media screen and (max-width: 768px) {
      #top .p-reason .l-col2__detail:before {
        width: 658px;
        height: 357.7px; } }
    @media screen and (max-width: 568px) {
      #top .p-reason .l-col2__detail:before {
        width: 484px;
        height: 259.5px; } }
